import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
const Footer = () => {
  const router = useRouter();
  const selfRedirectArray: string[] = ['Microbusinesses', 'Security'];
  const footerItems = [
    {
      label: 'Use cases',
      childItems: [
        { label: 'Nonprofits', url: '' },
        { label: 'Microbusinesses', url: '/microbusinesses' },
      ],
    },
    {
      label: 'Resources',
      childItems: [
        { label: 'Blog', url: 'https://makewebbetter.com/blog/' },
        {
          label: 'Knowledge Base',
          url: 'https://support.makewebbetter.com/hubspot-apps/category/hubspot-formpay-integration/',
        },
        {
          label: 'Documentation',
          url: 'https://docs.makewebbetter.com/hubspot-formpay-integration/',
        },
        {
          label: 'Testimonials',
          url: 'https://app.hubspot.com/ecosystem/39992237/marketplace/apps/marketing/ecommerce/formpay-754041',
        },
      ],
    },
    {
      label: 'Company',
      childItems: [
        {
          label: 'Cookie Policy',
          url: 'https://makewebbetter.com/cookie-policy/',
        },
        {
          label: 'Terms and Conditions',
          url: 'https://makewebbetter.com/terms-and-conditions/',
        },
        { label: 'Partners', url: 'https://makewebbetter.com/partners/' },
        {
          label: 'Reviews',
          url: 'https://makewebbetter.com/makewebbetter-reviews-ratings/',
        },
        { label: 'Security', url: '/security' },
      ],
    },
  ];

  return (
    <section className='tw-h-[1200px] tw-w-full tw-bg-darkBlack2 md:tw-h-[850px] lg:tw-h-[650px]'>
      <section className='tw-relative tw-mx-[11px] tw-max-w-[1024px] sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px] xl:tw-mx-auto'>
        <section className='tw-shadow-[0_20px_24px_-4px_rgba(16, 24, 40, 0.1),0_8px_8px_-4px_rgba(16,  24, 40, 0.4)] tw-absolute tw-left-[50%] tw-top-[-6rem] tw-mb-3 tw-flex tw-h-auto tw-w-[97%] -tw-translate-x-1/2 tw-flex-col  tw-items-center tw-justify-between tw-rounded-[16px] tw-border tw-border-formPay300 tw-bg-formPay300 tw-p-8 lg:tw-flex-row'>
          <section className='tw-flex tw-flex-col'>
            {' '}
            <section
              tabIndex={0}
              className='tw-my-2 tw-text-[24px] tw-font-fontBold tw-leading-[32px] tw-text-white  xl:tw-text-[30px] xl:tw-leading-[38px]'
            >
              Why use a bunch of payment apps when one is enough.
            </section>
            <section
              tabIndex={0}
              className='tw-my-2 tw-text-[18px] tw-font-fontLight tw-text-[#F3EBFE] xl:tw-text-[20px]'
            >
              Get FormPay to sort all your payment related business inside
              HubSpot.
            </section>
          </section>
          <section className='tw-my-8 tw-w-full md:tw-w-[280px]'>
            <Link href='/onboarding'>
              <button
                tabIndex={0}
                className='tw-flex tw-h-[48px] tw-w-full tw-justify-center tw-rounded-[8px] tw-border-[1px] tw-bg-white tw-p-[12px_20px] tw-text-smallText tw-font-fontMedium tw-text-darkBlack'
              >
                Try for free{' '}
                <span
                  className='tw-ml-2 tw-text-white'
                  role='img'
                  aria-label='try for free'
                >
                  <Image
                    src={`${router.basePath}/assets/images/BlackArrow.webp`}
                    alt='Try for free'
                    width={20}
                    height={20}
                  />
                </span>
              </button>
            </Link>
          </section>
        </section>
        <section className='tw-absolute tw-left-[50%] tw-mb-3 tw-mt-[280px] tw-flex tw-h-auto tw-w-[100%] -tw-translate-x-1/2 tw-flex-col tw-justify-start tw-px-0 tw-pb-0 tw-pt-16 sm:tw-justify-between sm:tw-p-16 md:tw-mt-[150px] lg:tw-mt-52'>
          <section className=' tw-grid tw-grid-cols-10 tw-gap-10 sm:tw-grid-cols-12'>
            <section className='tw-col-span-10 sm:tw-col-span-12 lg:tw-col-span-4 lg:tw-col-start-1'>
              <section className='tw-mt-10 tw-flex tw-w-auto tw-flex-col md:tw-mt-auto'>
                <section tabIndex={0} role='img' aria-label='FormPay logo'>
                  <Image
                    src={`${router.basePath}/assets/images/Formpay-logo-light.svg`}
                    alt='FormPay logo'
                    width={170}
                    height={60}
                  />
                </section>
                <section tabIndex={0} className='tw-my-4 tw-text-normalWhite '>
                  Collect payments for nonprofits, events, coaching business
                  with HubSpot payment forms.
                </section>{' '}
              </section>
            </section>
            <div className='tw-grid-cols-12'></div>
            <section className='tw-col-span-10 tw-flex tw-flex-wrap tw-justify-between tw-gap-3 lg:tw-col-span-6 lg:tw-col-start-7'>
              {footerItems.map((parentItem, i) => {
                return (
                  <section key={i} className='tw-flex tw-flex-col'>
                    <section
                      className='tw-my-4 tw-text-normalWhite'
                      tabIndex={0}
                    >
                      {parentItem?.label}
                    </section>
                    {parentItem?.childItems.map(item => (
                      <Link
                        href={item?.url}
                        key={item?.label}
                        target={`${
                          selfRedirectArray.includes(item?.label)
                            ? '_self'
                            : '_blank'
                        }`}
                      >
                        <section
                          tabIndex={0}
                          className='tw-my-2 tw-text-lightGrey2'
                        >
                          {item?.label}
                        </section>
                      </Link>
                    ))}
                  </section>
                );
              })}
            </section>
          </section>
          <section
            tabIndex={0}
            className='tw-mx-auto tw-mb-6 tw-mt-8 tw-text-lightBlack'
          >
            Trusted By
          </section>
          <section className='tw-my-6 tw-grid tw-grid-cols-10 tw-gap-10'>
            <section
              className='tw-col-span-3 tw-flex tw-justify-start tw-gap-3 sm:tw-justify-center'
              role='img'
              aria-label='partners'
            >
              <Link
                href='https://www.google.com/partners/agency?id=7988966922'
                target='_blank'
              >
                <Image
                  tabIndex={0}
                  alt='google partner'
                  src={`${router.basePath}/assets/images/Google-premier-partner.svg`}
                  width={74}
                  height={70}
                  className='tw-h-[34px] tw-w-[37px] sm:tw-h-[60px] sm:tw-w-[64px]'
                />
              </Link>

              <Link
                href='https://www.hubspot.com/agencies/makewebbetter'
                target='_blank'
              >
                <Image
                  tabIndex={0}
                  alt='HubSpot elite'
                  src={`${router.basePath}/assets/images/HubSpot-elite-batch.svg`}
                  width={74}
                  height={70}
                  className='tw-h-[34px] tw-w-[37px] sm:tw-h-[60px] sm:tw-w-[64px]'
                />
              </Link>
            </section>

            <section
              className='tw-col-span-4 tw-flex tw-justify-start tw-gap-3 sm:tw-justify-center'
              role='img'
              aria-label='partners'
            >
              <Image
                tabIndex={0}
                src={`${router.basePath}/assets/images/Footer Logo_ISO.svg`}
                alt='iso certified'
                width={64}
                height={60}
                className='tw-h-[34px] tw-w-[37px] sm:tw-h-[60px] sm:tw-w-[64px]'
              />
              <Image
                tabIndex={0}
                src={`${router.basePath}/assets/images/Footer Logo_GDPR.svg`}
                alt='gdpr'
                width={64}
                height={60}
                className='tw-h-[34px] tw-w-[37px] sm:tw-h-[60px] sm:tw-w-[64px]'
              />
              <Image
                tabIndex={0}
                src={`${router.basePath}/assets/images/Footer Logo_AICPA.svg`}
                alt='aicpa soc'
                width={64}
                height={60}
                className='tw-h-[34px] tw-w-[37px] sm:tw-h-[60px] sm:tw-w-[64px]'
              />
            </section>
            <section
              className='tw-col-span-3 tw-flex tw-justify-center'
              role='img'
              aria-label='partners'
            >
              <Link href='https://makewebbetter.com/' target='_blank'>
                <Image
                  tabIndex={0}
                  src={`${router.basePath}/assets/images/image-14.svg`}
                  alt='makewebbetter'
                  width={84}
                  height={41}
                  className='tw-h-[34px] tw-w-[37px] sm:tw-h-[60px] sm:tw-w-[64px]'
                />
              </Link>
            </section>
          </section>
          <section className='tw-my-6 tw-grid tw-h-[1px] tw-grid-cols-12 tw-bg-lightWhite'></section>

          <section className='tw-mt-6 tw-grid tw-grid-cols-12'>
            <section
              tabIndex={0}
              className='tw-col-span-12 tw-mb-5 tw-flex tw-justify-start tw-font-fontMedium tw-text-lightGrey3 lg:tw-col-span-6'
            >
              <Link
                href='/privacy'
                target='_blank'
                className='tw-mr-2 tw-font-fontMedium tw-text-lightGrey3'
              >
                Privacy Policy
              </Link>
            </section>
            <section
              tabIndex={0}
              className='tw-col-span-12 tw-flex tw-justify-start tw-font-fontLight tw-text-normalWhite lg:tw-col-span-6 lg:tw-justify-end'
            >
              Copyright ©MakeWebBetter. All Rights Reserved
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};
export default Footer;
