import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import FormattedText from './FormattedText';

const NewReviews = () => {
  const router = useRouter();
  const [direction, setDirection] = useState(0);

  const xVariants = {
    initial: (directionValue: any) => {
      return { x: directionValue > 0 ? 50 : -50, opacity: 0 };
    },
    animate: {
      x: 0,
      opacity: 1,

      transition: {
        x: {
          type: 'spring',
          stiffness: 300,
          damping: 30,
        },
        opacity: { duration: 0.2 },
      },
    },
    exit: (directionValue: any) => {
      return {
        x: directionValue > 0 ? -50 : 50,
        opacity: 0,

        transition: {
          x: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
          },
          opacity: { duration: 0.2 },
        },
      };
    },
  };
  const reviewsList = [
    {
      image: `${router.basePath}/assets/images/LaurenPotter.jpg`,
      title: 'Rapid Response Time',
      description:
        'Fast link up to solve problems and very positive experience. They are insightful and helpful during the implementation process.',
      solveText: 'Making payments via HubSpot Easier',
      reviewer: '— Lauren Potter',
      designation: 'Photography',
    },
    {
      image: `${router.basePath}/assets/images/brendan.jpg`,
      title:
        "Great product, Great customer Service, Couldn't ask for anything better",
      description:
        'I have been searching for a while for a product that does every thing this does, but not only that teh customer service team is amazing and they listen o our needs as their clients and are very helpful',
      solveText:
        'Being able to have a direct payment gateway on a landing page is amazing, without having the prospect navigating away from the page.',
      reviewer: '— Brendan T',
      designation: 'Photography',
    },
    {
      image: `${router.basePath}/assets/images/pramod.jpg`,
      title: 'Good app',
      description:
        'Nice app for converting HubSpot forms to payment forms. Easy to use, multiple payment gateways available and excellent support.',
      solveText: 'Forms for payment',
      reviewer: '— Pramod Rapeti',
      designation: 'Hospital Health Care',
    },
  ];
  const [index, setIndex] = useState(0);
  const nextStep = (specificIndex = -1) => {
    setDirection(1);
    if (specificIndex >= 0) setIndex(specificIndex);
    else {
      if (index === reviewsList.length - 1) setIndex(0);
      else setIndex(index + 1);
    }
  };
  const prevStep = (specificIndex = -1) => {
    setDirection(-1);
    if (specificIndex >= 0) setIndex(specificIndex);
    else {
      if (index === 0) setIndex(reviewsList.length - 1);
      else setIndex(index - 1);
    }
  };

  return (
    <section>
      <FormattedText
        parentClassNames=''
        childClassNames='tw-text-[#7936f4] tw-font-fontBold tw-leading-[24px] tw-my-4 tw-text-normalText'
      >
        {'Reviews'}
      </FormattedText>
      <FormattedText
        parentClassNames=''
        childClassNames='tw-text-darkerGrey tw-text-[24px] md:tw-text-[28px] lg:tw-text-veryLargeText tw-leading-[32px] md:tw-leading-[34px] lg:tw-leading-[44px] tw-font-fontBold tw-mt-8 tw-mb-16'
      >
        {'What others say about FormPay?'}
      </FormattedText>
      <section className=' tw-grid tw-grid-cols-12'>
        <section className='tw-col-span-12 tw-flex tw-h-[1000px] tw-flex-col tw-justify-between tw-rounded-[2rem] tw-border-2 tw-border-solid tw-border-[#1C2736] tw-bg-[#1C2736] tw-p-8 md:tw-h-[650px] md:tw-p-16 lg:tw-h-[500px]'>
          <AnimatePresence initial={false} custom={direction} mode='popLayout'>
            <motion.section
              variants={xVariants}
              key={reviewsList[index]?.title}
              animate='animate'
              initial='initial'
              exit='exit'
              custom={direction}
            >
              <motion.section className=' tw-grid tw-grid-cols-12 md:tw-gap-16'>
                <motion.section
                  className='tw-col-span-12 md:tw-col-span-5 xl:tw-col-span-4'
                  role='img'
                  aria-label='review image'
                >
                  <motion.img
                    src={reviewsList[index]?.image}
                    className='tw-mb-4 tw-w-[100%] tw-rounded-2xl'
                    alt='review image'
                    tabIndex={0}
                  />
                </motion.section>
                <motion.section className='tw-col-span-12 tw-ml-0 sm:tw-ml-6 md:tw-col-span-7 lg:tw-ml-0'>
                  <motion.section tabIndex={0} className='tw-my-4 tw-flex'>
                    {[1, 2, 3, 4, 5].map(item => (
                      <motion.img
                        key={item}
                        className='tw-mx-1 tw-h-[20px] tw-w-[20px]'
                        src={`${router.basePath}/assets/images/Star.webp`}
                        alt='review rating'
                      />
                    ))}
                  </motion.section>
                  <motion.section
                    tabIndex={0}
                    className='tw-mx-1 tw-my-4 tw-text-[18px] tw-font-fontMedium tw-leading-[24px] tw-text-[#ffffff] md:tw-text-[24px] md:tw-leading-[34px]'
                  >
                    {reviewsList[index]?.title}
                  </motion.section>
                  <motion.section
                    tabIndex={0}
                    className='tw-mx-1 tw-my-4 tw-text-[16px] tw-font-fontLight tw-text-[#ecedee]'
                  >
                    {reviewsList[index]?.description}
                  </motion.section>
                  <motion.section
                    tabIndex={0}
                    className='tw-mx-1 tw-my-4 tw-text-[16px] tw-font-fontBold tw-text-[#ecedee]'
                  >
                    What problem are you trying to solve?
                    <br />
                    <motion.span className='tw-font-fontLight'>
                      {reviewsList[index]?.solveText}
                    </motion.span>
                  </motion.section>
                  <motion.section
                    tabIndex={0}
                    className='tw-mx-1 tw-my-4 tw-text-[18px] tw-font-fontMedium tw-text-[#ffffff]'
                  >
                    {reviewsList[index]?.reviewer}
                    <br />
                    <motion.span className='tw-text-[16px] tw-font-fontLight'>
                      {reviewsList[index]?.designation}
                    </motion.span>
                  </motion.section>
                </motion.section>
              </motion.section>
            </motion.section>
          </AnimatePresence>
          <section className='tw-mt-4 tw-grid tw-grid-cols-12 tw-items-center'>
            <section className='tw-col-span-6'>
              <section style={{ display: 'flex', gap: '10px' }}>
                {reviewsList.map((item, i) => (
                  <section
                    key={item.title}
                    onClick={() => {
                      if (i > index) nextStep(i);
                      else if (i < index) prevStep(i);
                    }}
                    role='img'
                    aria-label='review image'
                  >
                    <Image
                      alt='review image'
                      width={10}
                      height={10}
                      src={
                        i === index
                          ? `${router.basePath}/assets/images/ActiveImage.webp`
                          : `${router.basePath}/assets/images/InActiveImage.webp`
                      }
                    />
                  </section>
                ))}
              </section>
            </section>
            <section className='tw-col-span-6'>
              <section style={{ display: 'flex', justifyContent: 'end' }}>
                <button
                  tabIndex={0}
                  onClick={() => {
                    prevStep();
                  }}
                  className=' tw-rounded-full tw-px-2 tw-font-fontBold tw-text-[#FFFFFF]'
                >
                  <Image
                    alt='prev icon'
                    src={`${router.basePath}/assets/images/LeftIcon.webp`}
                    width={44}
                    height={44}
                  />
                </button>
                <button
                  tabIndex={0}
                  onClick={() => {
                    nextStep();
                  }}
                  className=' tw-rounded-full tw-px-2 tw-font-fontBold tw-text-[#FFFFFF]'
                >
                  <Image
                    alt='next icon'
                    src={`${router.basePath}/assets/images/RightArrow.webp`}
                    width={44}
                    height={44}
                  />
                </button>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};
export default NewReviews;
