import React from 'react';
interface FormattedTextInterface {
  parentClassNames?: string;
  childClassNames?: string;
  children: React.ReactNode;
}
const FormattedText = (props: FormattedTextInterface) => {
  const { parentClassNames, childClassNames, children } = { ...props };

  return (
    <section
      tabIndex={0}
      className={`tw-flex tw-justify-center ${parentClassNames}`}
    >
      <section className={`tw-text-center ${childClassNames}`}>
        {children}
      </section>
    </section>
  );
};

export default FormattedText;
